import toastr from 'toastr';
window.toastr = toastr;

$(document).ready(function () {

  if(gon.sign_in){
    if(gon.sign_in.is_token_expired){
      setTimeout(function(){
        Shared.showModalForElement('#NotifyTokenExpired');
      }, 500);
    }
    else if(gon.sign_in.is_show_popup_reset_pass){
      setTimeout(function(){
          toastr.success('成功したメールを送信');
      },500)
    }
  }

  initEnablePaymentType($("input[name='register_type']:checked").val());

  $(document).on("click", "input[name='register_type']", function () {
    var value = $(this).val()
    initEnablePaymentType(value)
  })

  function initEnablePaymentType(type) {
    $('#collapseOne').find('input').removeClass('is-invalid').removeAttr('required');
    $('#collapseTwo').find('input').removeClass('is-invalid').removeAttr('required');
    if (parseInt(type) == 0) {
      $('#form-register-user').addClass('d-none');
      $('#show_info_company').addClass('d-none');
      $('#form-register-user').find('input').attr('disabled', true);
      $('#collapseOne').find('input').removeAttr('disabled').attr('required', true);
      $('#form-register-user').find('input').removeAttr('required');
    } else if (parseInt(type) == 1) {
      $('#collapseTwo').find('input').removeAttr('disabled').attr('required', true);
    }
  }

  $('body').on('click', '.check_type_register', function (e) {
    if ($(this).is(':checked')) {
      let type_check = $(this).attr('id');
      if (type_check == 'check_register_company') {
        $('input#type_keycode').val('true');
        $('#form-register-user').addClass('d-none');
        $('#show_info_company').addClass('d-none');
        $('#form-register-user').find('input').attr('disabled', true);
        $('#form-register-user').attr('register_user', 'false');

        $('#form-register-user').find('input').removeAttr('required');
      } else {
        $('input#type_keycode').val('false');
      }
    }
  })

  $('button.sign-up-btn').on('click', function (e) {
    e.preventDefault();
    let check_type_register = $('input.check_type_register:checked').attr('id');
    let type_check = $("input[name='register_type']:checked").val();
    let flag = check_validate('sign-up-btn');
    e.preventDefault();
    if (parseInt(type_check) == 0) {
      is_submit(flag);
    } else {
      // if (check_type_register == 'check_register_company'){
      //   $('#form-register-user').addClass('d-none');
      //   $('#show_info_company').addClass('d-none');
      //   $('#form-register-user').find('input').attr('disabled', true);
      //   $('#form-register-user').attr('register_user', 'false');
      //   $('button.sign-up-user').addClass('d-none');
      //   $('button.sign-up-btn').removeClass('d-none');
      //   $('#form-register-user').find('input').removeAttr('required');
      //   is_submit(flag)
      // }else{
      if (flag) {
        e.preventDefault();
        let key_code = $('#user_keycode').val();
        let type_keycode = $('input#type_keycode').val();
        $.ajax({
          method: 'POST',
          url: Routes.owner_register_user_for_keycode_path(),
          dataType: 'json',
          data: {
            'key_code': key_code,
            'type_keycode': type_keycode
          },
          headers: {
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
          }
        })
          .done(function (data) {
            if (data.not_present) {
              toastr.error('会社は存在しません', '', { closeButton: true, progressBar: true });
            } else {
              $('#form-register-user').removeClass('d-none');
              $('#show_info_company').removeClass('d-none');
              $('.createcloud_of_akapon').text(data.akapon ? 'アカポン' : 'Create Cloud')
              $('.name_company_resigter').text(data.company.name).attr('company_id', data.company.id);
              $('#form-register-user').find('input').attr('disabled', false);
              $('#form-register-user').attr('register_user', 'true');
              $('button.sign-up-btn').addClass('d-none');
              $('button.sign-up-user').removeClass('d-none').text('メンバー登録を申請する');
              $('.check-policy').removeClass('d-none')
              $('#form-register-user').find('input').attr('required', true);
              $('#form-register-user').find('input#department').removeAttr('required');
              $('#form-register-user').find('input#position').removeAttr('required');
              $('#company-name').text(`${data.company.name}`)
              $('button.sign-up-user').prop('disabled', true);
              hide_input_keycode();
              toastr.success('下記を入力してメンバー登録を行ってください。', '', { closeButton: true, progressBar: true });
            }
          })
          .fail(function (data) {
            toastr.error('会社は存在しません', '', { closeButton: true, progressBar: true });
          })
      } else {
        return
      }
      // }
    }
  })

  function is_submit(flag) {
    if (flag) {
      $('#new_user').submit();
    } else {
      return
    }
  }

  function hide_input_keycode() {
    $('.content-register').find('.text-header').addClass('d-none');
    $('.content-register').find('#registerTitle').addClass('d-none');
    $('.content-register').find('.register_email_outer').addClass('d-none');
    $('.check-policy').addClass('d-none');
    $('button.sign-up-user').addClass('px-5 mt-3');
    $('#register-keycode').addClass('d-none');
    $('#back-signin').addClass('d-none')
    $('.show-keycode-register').removeClass('d-none')
  }

  function check_validate(element) {
    let fields;
    if (element == 'sign-up-btn')
      fields = $('#new_user').find('input');
    else
      fields = $('#form-register-user').find('input');

    let flag = true;
    $.each(fields, function (index, field) {
      if ($(field).attr('required') !== undefined && $(field).is('input[type="text"]')) {
        $(field).val($(field).val().trim());
        if ($(field).val() === '') {
          flag = false;
          checkValidation($(field), '空白にはできません');
        } else {
          if ($(field).attr('name') === 'user[email]') {
            let expression = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/;
            let regex = new RegExp(expression)
            if (!($(field).val().match(regex))) {
              checkValidation($(field), I18n.t('admin.attributes.email.invalid'));
              flag = false;
            }
          } else if ($(field).attr('name') === 'user[name]') {
            if (Shared.checkValidationUserName($(field).val())) {
              checkValidation($(field), '', false);
              Popup.alert('「お名前」に利用できない文字があります。<br>お名前を変更してください。')
              flag = false;
            }
          }else {
            if (element == 'sign-up-btn') {
              if ($(field).val().length !== 12) {
                checkValidation($(field), 'スペース、ハイフンなどを含まない12桁のみ使用できます。');
                flag = false;
              }
            }
          }
        }
      } else {
        if (element == 'sign-up-user') {
          if ($(field).is('input[type="password"]')) {
            let expression_for_pass = /(?=.*[a-zA-Z])(?=.*\d).{8,32}/
            let regex_pass = new RegExp(expression_for_pass);
            if (!$(field).val().match(regex_pass)) {
              checkValidation($(field), '英・数を各1文字以上で最低8文字以上32文字以下入力してください。E.g: abAB1122');
              flag = false;
            } else {
              if (($(field).val() !== $('input[name="user[password]"]').val()) && (!$(field).is($('input[name="user[password]"]')))) {
                checkValidation($(field), 'パスワードとパスワードの再入力が同じではない');
                flag = false;
              }
            }
          }
        }
      }
    })
    return flag;
  }
  $('body').on('click', '.sign-up-user:enabled', function (e) {
    // if (!$('#check_register_user').is(':checked')) return;
    e.stopPropagation();
    e.preventDefault();

    let type_check = $("input[name='register_type']:checked").val();
    if (parseInt(type_check) == 0) {
      // register mail
      let flag = check_validate('sign-up-btn');
      if (flag) {
        const email = $('#user_email').val() || $('input#email').val();
        Shared.callAjax(Routes.owner_check_signup_path(), { email: email })
        .done(function(data){
          is_submit(flag);
        }).fail(function(data){
          if(data.responseJSON.message_error){
            Popup.alert(data.responseJSON.message_error)
          }else if(data.responseJSON.status){
            PasswordReset._popupLink(email, 'comfirmEmail');
          }
        })
      }
      else
        return

    } else {
      // register keycode
      let flag = check_validate('sign-up-user');
      if (flag) {
        let user = {
          name: $('#form-register-user').find('input#name').val(),
          company_id: $('.name_company_resigter').attr('company_id'),
          department: $('#form-register-user').find('input#department').val(),
          position: $('#form-register-user').find('input#position').val(),
          email: $('#form-register-user').find('input#email').val(),
          password: $('#form-register-user').find('input#password').val(),
          password_comfirmation: $('#form-register-user').find('input#password_comfirmation').val(),
          furigana_name: $('#form-register-user').find('input#furigana_name').val()
        }
        $.ajax({
          method: 'POST',
          url: '/register_user',
          dataType: 'json',
          data: { user: user },
          headers: {
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
          }
        })
          .done(function (data) {
            if (data.create == 'false') {
              if (data.error_by == 'user_present') {
                checkValidation($('#form-register-user').find('input#email'), data.message);
              }
              if (data.error_by == 'password_compase') {
                checkValidation($('#form-register-user').find('input#password_comfirmation'), data.message);
              }
              toastr.error(data.message, '', { closeButton: true, progressBar: true });
            } else {
              toastr.success('正常に登録', '', { closeButton: true, progressBar: true });
              // $('#companyName').text($('.name_company_resigter').text())
              // console.log($('#successMemberRegister'))
              // $('#successMemberRegister').modal('show')
              Popup.confirm('', {
                description: `${$('.name_company_resigter').text()}の<br>メンバー申請が完了しました。<br>管理者が承認後、${$('.createcloud_of_akapon').text()}をご利用頂けます。`,
                confirmText: 'OK',
                confirmClass: 'btn-secondary',
                confirmColor: 'secondary',
                cancelBtn: 'false',
                success: () => {
                  location.replace('/signin');
                },
              })


              // setTimeout(() => {
              //   location.replace('/signin');
              // }, 2000)
            }
          })
          .fail(function (data) {

          })
      } else {
        return
      }
    }
  })

  if(window.location.search.includes('auth_token=true')){
    const url = new URL(window.location.href)
    url.searchParams.delete('auth_token')
    Shared.changeCurrentUrl(url.href)
    $('#btn-show-otp-token').trigger('click');
  }

  $('#form-sign-in-pw, #form-sign-in-email').on('input', function(){
    enabledSubmitButton($('form#login-form').find('.sign-in-btn'), ($('#form-sign-in-pw').val() && $('#form-sign-in-email').val()))
  });

  $('.reset-form.input-forgot').on('input', function(){
    enabledSubmitButton($('button.forgot-btn'), $('.reset-form.input-forgot').val())
  });

  $('#user_keycode').on('input', function(){
    enabledSubmitButton($('button.sign-up-btn'), $('#user_keycode').val())
  });

  $('#name.keycode-form, #email.keycode-form, #password.keycode-form, #password_comfirmation.keycode-form').on('input', function(){
    enabledSubmitButton($('button.sign-up-user'), ($('#name.keycode-form').val() && $('#email.keycode-form').val()))
  });

  $('input.form-reset-pw, input.form-reset-pw-confirm').on('input', function(){
    enabledSubmitButton($('input.btn-reset-form'), ($('input.form-reset-pw').val() && $('input.form-reset-pw-confirm').val()))
  });

  $('#form-sign-in-pw, #form-sign-in-email').on('input', function(){
    enabledSubmit($('form#login-form').find('#check-user-password'), ($('#form-sign-in-pw').val() && $('#form-sign-in-email').val()))
  });

  $('.input-otp-token').find("input[name='token']").on('input', function(){
    enabledSubmit($('#btn-sign-in-token'), ($('.input-otp-token').find("input[name='token']").val()))
  });

  function checkValidation(element, message, resetValue = true) {
    element.addClass('is-invalid');
    if(resetValue) {
      element.val('');
      element.attr('placeholder', message);
    }
    setTimeout(function () {
      element.removeClass('is-invalid');
    }, 5000);
  }

  function enabledSubmit(button, is_input_value){
    if (is_input_value) {
      button.removeClass('disabled');
    } else {
      button.addClass('disabled');
    }
  }

  function enabledSubmitButton(button, is_input_value){
    if (is_input_value) {
      button.prop('disabled', false);
    } else {
      button.prop('disabled', true);
    }
  }
})
